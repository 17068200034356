/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
Rails.start()

import "../src/scss/application.scss"

import "@popperjs/core"
import "bootstrap"

window.addEventListener('ajax:success', (e) => {
	const form = document.getElementById('regform')
	form.parentElement.parentElement.innerHTML = e.detail[0]
})

window.addEventListener('ajax:error', (e) => {
	const form = document.getElementById('regform')
	form.parentElement.innerHTML = e.detail[0]
})

let els = {
	img: {},
	hero: {}
}

const handleResize = () => {
	const windowWidth = window.innerWidth

	if (windowWidth < 992) {
		const half = els.img.height / 2
		els.img.setAttribute('style', `margin-bottom:-${half}px`)
		els.hero.setAttribute('style', `margin-bottom:${half}px`)
	} else {
		els.img.setAttribute('style', '')
		els.hero.setAttribute('style', '')
	}
}

document.addEventListener("DOMContentLoaded", function() {
	const img = document.getElementById('guide-image')
	els.img = img

	const hero = document.querySelector('.hero')
	els.hero = hero

	handleResize()
})

window.addEventListener('resize', handleResize)
